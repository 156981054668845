import './App.css';

import Axolotl from "./axolotl";

function App() {
  return (
    <div className="App">
        <div className="">
            <Axolotl />
            {/* <img src="/images/axolotl.gif" alt="axolotl" /> */}
        </div>
    </div>
  );
}

export default App;
