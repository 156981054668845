exports.BUBBLES = [
    {
      x: "200",
      y: "50",
      content: "axolotl.gif",
      color: "white"
    },
  ];
  
  exports.SCROLL_SPEED = 1.5;
  